/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 3v12.5c0 1.93-1.57 3.5-3.5 3.5S14 17.43 14 15.5s1.57-3.5 3.5-3.5c.54 0 1.05.12 1.5.34V6.47L9 8.6v8.9C9 19.43 7.43 21 5.5 21S2 19.43 2 17.5 3.57 14 5.5 14c.54 0 1.05.12 1.5.34V6l14-3z" _fill="#000"/>'
  }
})
