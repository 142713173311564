



















import {Component, Vue} from 'vue-property-decorator';
import TrackSelectCompilations from '@/components/track/select-compilations/TrackSelectCompilations.vue';
import UButton from '@/ui-lib/button/Button.vue';

export type CompilationsSelectModalParams = {
  selected?: number[];
  onSelect?: () => void;
}

@Component({
  components: {
    TrackSelectCompilations,
    UButton,
  }
})
export default class extends Vue {
  private selected: number[] = [];
  private onSelect: () => void = (() => {
    return;
  });

  private close(): void {
    this.$modal.hide('compilations-select');
  }

  private beforeOpen({params}: { params: CompilationsSelectModalParams }) {
    this.selected = params?.selected || [];
    this.onSelect = params?.onSelect || (() => {
      return;
    });
  }

  private select() {
    this.onSelect();
    this.close();
  }
}
