











import {Component, Vue} from 'vue-property-decorator';
import Modals from '@/components/modals/Main.vue';

@Component({
  components: {
    Modals,
  }
})
export default class BaseLayout extends Vue {
}
