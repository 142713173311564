

















































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UBlock extends Vue {
    private get hasTitleSlot(): boolean {
        return 'title' in this.$slots;
    }

    private get hasHeader(): boolean {
        return this.hasTitleSlot;
    }

    private get classNames() {
        return ['u-block', {
            'u-block__has-header': this.hasHeader,
        }];
    }
}
