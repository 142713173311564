import {Combination, Preset} from '@/types';
import {api} from '@/api/api';

export const PresetService = {
    list(): Promise<Preset[]> {
        return api.get('/preset/list');
    },

    item(presetId: number): Promise<Preset> {
        return api.get(`/preset/${presetId}`);
    },

    combinations(presetId: number): Promise<Combination[]> {
        return api.get(`/preset/${presetId}/combinations`);
    }
};
