/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240z"/>'
  }
})
