/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'music-note': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z" _fill="#000"/>'
  }
})
