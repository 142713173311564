





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Genre} from '@/types';
import {genre} from '@/store/modules/genre';
import TrackSelectCompilationsGenre from './TrackSelectCompilationsGenre.vue';

@Component({
    components: {
        TrackSelectCompilationsGenre,
    },
})
export default class TrackSelectCompilations extends Vue {
    @Prop({type: Array, default: () => []})
    private selected!: number[];

    private get genres(): Genre[] {
        return genre.context(this.$store).state.list;
    }
}
