import Vue, {VNodeDirective} from 'vue';

Vue.use((vue) => {
    const loadingClassName = 'v-loading';

    const toggleLoading = (el: HTMLElement, binding: VNodeDirective) => {
        const lEl = el.getElementsByClassName(loadingClassName).item(0);

        if (binding.value) {
            if (lEl) {
                return;
            }

            if (document.activeElement && el.contains(document.activeElement)) {
                const fEl = document.activeElement as HTMLElement;

                if ('blur' in fEl) {
                    fEl.blur();
                }
            }

            const loadingEl: HTMLElement = document.createElement('div');
            loadingEl.className = loadingClassName;
            loadingEl.appendChild(document.createElement('span'));
            loadingEl.appendChild(document.createElement('span'));

            el.style.position = 'relative';
            el.appendChild(loadingEl);
        } else {
            el.style.position = '';

            if (lEl) {
                lEl.remove();
            }
        }
    };

    vue.directive('loading', {
        bind: toggleLoading,
        update: toggleLoading,
    });
});
