import {Getters, Mutations, Actions, Module, createMapper} from 'vuex-smart-module';
import {MrUser} from '@/types';
import {AuthService, AuthLoginBody} from '@/api';

class AuthState {
    user: MrUser | null = null;
}

class AuthMutations extends Mutations<AuthState> {
    setUser(user: MrUser | null) {
        this.state.user = user;
    }
}

class AuthGetters extends Getters<AuthState> {
    get isAuth() {
        return this.state.user !== null;
    }
}

class AuthActions extends Actions<AuthState, AuthGetters, AuthMutations, AuthActions> {
    async loadAuth() {
        const user = await AuthService.current();
        this.mutations.setUser(user);
    }

    async login({email, password}: { email: string; password: string }) {
        const body: AuthLoginBody = {
            email, password,
        };

        await AuthService.login(body);
        await this.actions.loadAuth();
    }

    async logout() {
        await AuthService.logout();
        await this.actions.loadAuth();
    }
}

export const auth = new Module({
    state: AuthState,
    getters: AuthGetters,
    mutations: AuthMutations,
    actions: AuthActions,
});

export const authMapper = createMapper(auth);
