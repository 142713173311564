import {Getters, Mutations, Actions, Module} from 'vuex-smart-module';
import {Preset} from '@/types';
import {PresetService} from '@/api';

class PresetState {
  list: Preset[] = [];
  listLoaded = false;
}

class PresetMutations extends Mutations<PresetState> {
  setList(list: Preset[]) {
    this.state.list = list;
  }

  setListLoaded(listLoaded: boolean) {
    this.state.listLoaded = listLoaded;
  }
}

class PresetGetters extends Getters<PresetState> {
}

class PresetActions extends Actions<PresetState, PresetGetters, PresetMutations, PresetActions> {
  async loadList() {
    if (this.state.listLoaded) {
      return;
    }

    const list = await PresetService.list();
    this.mutations.setList(list);
    this.mutations.setListLoaded(true);
  }
}

export const preset = new Module({
  state: PresetState,
  getters: PresetGetters,
  mutations: PresetMutations,
  actions: PresetActions,
});
