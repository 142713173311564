/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.34 15.41l-4.59-4.58-4.59 4.58L5.75 14l6-6 6 6-1.41 1.41z" _fill="#000"/>'
  }
})
