/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.706 7.041a.999.999 0 000-1.414L18.37 3.291a.999.999 0 00-1.414 0l-1.832 1.832 3.75 3.75 1.832-1.832zM3 17.248v3.75h3.75L17.814 9.933l-3.75-3.75L3 17.248z" _fill="#000"/>'
  }
})
