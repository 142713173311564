export interface Preset {
  id: number;
  name: string;
  combinationCount: number;
}

export const createPreset = (): Preset => {
  return {
    id: 0,
    name: '',
    combinationCount: 0,
  };
}
