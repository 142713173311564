






import {Component, Prop, Vue} from 'vue-property-decorator';
import {CompilationListItem} from '@/types';
import UCheckbox from '@/ui-lib/checkbox/Checkbox.vue';

@Component({
    components: {
        UCheckbox,
    },
})
export default class TrackSelectCompilationsItem extends Vue {
    @Prop({required: true})
    private compilation!: CompilationListItem;

    @Prop({type: Array, default: () => []})
    private selected!: number[];

    private get isSelected() {
        return this.selected.includes(this.compilation.id);
    }

    private set isSelected(val: boolean) {
        const index = this.selected.indexOf(this.compilation.id);

        if (index >= 0) {
            this.selected.splice(index, 1);
        } else {
            this.selected.push(this.compilation.id);
        }
    }

    private get name(): string {
        return this.compilation.name;
    }
}
