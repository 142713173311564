/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 11v2h12.01l-5.505 5.505 1.414 1.414L19.84 12l-7.92-7.92-1.414 1.415L16.01 11H4z" _fill="#000"/>'
  }
})
