
























































































import {Component, Prop, Vue} from 'vue-property-decorator';

interface Item {
    content?: number;
    selected?: boolean;
    disabled?: boolean;
    breakView?: boolean;
}

@Component
export default class UPagination extends Vue {
    @Prop({required: true, type: Number})
    private total!: number;

    @Prop({type: Number, default: 0})
    private start!: number;

    @Prop({type: Number, default: 0})
    private limit!: number;

    @Prop({type: Number, default: 4})
    private readonly pageRange!: number;

    @Prop({type: Number, default: 1})
    private readonly marginPages!: number;

    private get page() {
        return this.start ? Math.ceil(this.start / this.limit) + 1 : 1;
    }

    private get pageCount(): number {
        return Math.ceil(this.total / this.limit);
    }

    private get pages(): Item[] {
        const items: Item[] = [];

        const setPageItem = (index: number) => {
            items.splice(index, 1, {
                content: index + 1,
                selected: index === (this.page - 1),
            });
        };

        const setBreakView = (index: number) => {
            items.splice(index, 1, {
                disabled: true,
                breakView: true,
            });
        };

        if (this.pageCount <= this.pageRange) {
            for (let index = 0; index < this.pageCount; index++) {
                setPageItem(index);
            }
        } else {
            const halfPageRange = Math.floor(this.pageRange / 2);

            for (let i = 0; i < this.marginPages; i++) {
                setPageItem(i);
            }

            let selectedRangeLow = 0;

            if (this.page - halfPageRange > 0) {
                selectedRangeLow = this.page - 1 - halfPageRange;
            }

            let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;

            if (selectedRangeHigh >= this.pageCount) {
                selectedRangeHigh = this.pageCount - 1;
                selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
            }

            for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                setPageItem(i);
            }

            if (selectedRangeLow > this.marginPages) {
                setBreakView(selectedRangeLow - 1);
            }

            if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                setBreakView(selectedRangeHigh + 1);
            }

            for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                setPageItem(i);
            }
        }

        return items;
    }

    private pageChange(newPage: number) {
        const newStart = (newPage - 1) * this.limit;

        if (this.start === newStart) {
            return;
        }

        this.$emit('change-start', newStart);
    }
}
