













































































import {Component, Vue} from 'vue-property-decorator';
import {CompilationListItem, CompilationTrackCount} from "@/types";
import UBlock from "@/ui-lib/block/Block.vue";
import {genre} from "@/store/modules/genre";
import {compilation} from "@/store/modules/compilation";
import {CompilationService} from "@/api";

export type CompilationSelectModalParams = {
  onSelect?: (val: CompilationListItem) => void;
};

@Component({
  components: {
    UBlock,
  },
})
export default class extends Vue {
  private counts: CompilationTrackCount[] = [];
  private isLoadingCount = false;
  private onSelect: (val: CompilationListItem) => void = () => {
    return;
  }

  private close(): void {
    this.$modal.hide('compilation-select');
  }

  private beforeOpen({params}: { params: CompilationSelectModalParams }) {
    this.loadCounts();
    this.onSelect = params?.onSelect || (() => {
      return;
    });
  }

  private get genres() {
    return genre.context(this.$store).state.list;
  }

  private get compilations() {
    return compilation.context(this.$store).state.list;
  }

  private genreCompilations(genreId: number) {
    return this.compilations.filter((c) => c.genreId === genreId);
  }

  private compilationCount(compilationId: number): number {
    return this.counts.find((c) => c.id === compilationId)?.count || 0;
  }

  private async loadCounts() {
    this.isLoadingCount = true;

    try {
      this.counts = await CompilationService.trackCount();
    } catch (e) {
      alert(e.message);
    }

    this.isLoadingCount = false;
  }

  private select(val: CompilationListItem) {
    this.onSelect(val);
    this.close();
  }
}
