















































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

let id = 0;

@Component
export default class UTextField extends Vue {
  @Prop({type: [String, Number], default: ''})
  private value!: string | number;

  @Prop({type: String})
  private label!: string;

  @Prop({default: 'text'})
  private type!: string;

  @Prop({type: String})
  private placeholder!: string;

  @Prop({type: Boolean, default: false})
  private required!: boolean;

  @Prop({type: Boolean, default: false})
  private disabled!: boolean;

  @Prop({type: Number, default: 2})
  private rows!: number;

  @Prop({type: [String, Array], default: ''})
  private error!: string | string[];

  private id: string;
  private valueLocal: string | number = this.value;

  constructor() {
    super();

    id++;

    this.id = `tf-${id}`;
  }

  @Watch('value')
  private onValueChanged(val: string) {
    this.valueLocal = val;
  }

  @Watch('valueLocal')
  private onValueLocalChanged(val: string) {
    this.valueLocal = val;
    this.$emit('input', val);
    this.$emit('update:error', undefined);
  }

  private get classNames() {
    return ['u-text-field', {
      'u-text-field__disabled': this.disabled,
      'u-text-field__has-value': !!this.valueLocal,
      'u-text-field__has-error': this.hasError,
    }];
  }

  private get errorMessages(): string[] {
    let res: string[] = [];

    if (Array.isArray(this.error)) {
      res = this.error;
    } else if (this.error) {
      res = [this.error];
    }

    return res;
  }

  private get hasError(): boolean {
    return this.errorMessages.length > 0;
  }
}
