























































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class UButton extends Vue {
  @Prop({type: String, default: 'button'})
  private type!: string;

  @Prop({type: String, default: 'primary'})
  private theme!: string;

  @Prop({type: Boolean, default: false})
  private disabled!: boolean;

  @Prop({type: Boolean, default: false})
  private loading!: boolean;

  @Prop({type: Boolean, default: false})
  private small!: boolean;

  @Prop({type: String})
  private iconLeft!: string;

  @Prop({type: String})
  private iconRight!: string;

  private get isDisabled() {
    return this.disabled || this.loading;
  }

  private get classNames() {
    return ['u-button', 'u-button__' + this.theme, {
      'u-button__disabled': this.isDisabled,
      'u-button__small': this.small,
    }];
  }

  private clickHandler(e: MouseEvent) {
    return this.$emit('click', e);
  }
}
