

























import {Component, Prop, Vue} from 'vue-property-decorator';

export type AlertTypes = 'info' | 'error' | 'success' | 'warning';

@Component
export default class UAlert extends Vue {
    @Prop({type: String, default: 'info'})
    private type!: AlertTypes;

    private get classNames() {
        return ['u-alert', 'u-alert__' + this.type];
    }
}
