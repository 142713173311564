import {TrackListItem} from '@/types';
import {api} from '@/api/api';

export interface TrackServiceListParams {
  start?: number;
  limit?: number;
  compilationsIds?: number[];
  order_column?: 'id' | 'likes' | 'dislikes' | 'listeners_per_month' | 'skips_per_month' | 'pl_pos';
  order_dir?: 'asc' | 'desc';
  playlistID?: number;
  excludePlaylistID?: number;
}

export interface TrackServiceList {
  total: number;
  list: TrackListItem[];
}

export const TrackService = {
  list(params: TrackServiceListParams): Promise<TrackServiceList> {
    // eslint-disable-next-line
    const query: Record<string, any> = {...params};

    if (params.compilationsIds) {
      query['compilations_ids'] = params.compilationsIds.join(',');
      delete query.compilationsIds;
    }

    return api.get('/track/list', query);
  },

  getMP3DownloadURL(ids: number[]): Promise<{ url: string }> {
    return api.get('/track/download-url', {
      ids: ids.join(','),
    });
  },

  uploadAlbum(req: {
    artistName: string;
    albumName: string;
    albumCover: File | null;
    tracks: File[];
  }) {
    const formData = new FormData();
    formData.set('albumName', req.albumName);
    formData.set('artistName', req.artistName);

    if (req.albumCover) {
      formData.set('albumCover', req.albumCover);
    }

    req.tracks.forEach((tr, i) => {
      formData.set(`tracks[${i}]`, tr);
    });

    return api.post('/track/upload-album', formData);
  }
};
