











































































































import {Component, Vue} from 'vue-property-decorator';
import {CompilationListItem, TrackListItem} from "@/types";
import {TrackService, TrackServiceListParams} from "@/api";
import TrackTableList from "@/components/track/TrackTableList.vue";
import {TableField} from "@/ui-lib/table/Table.vue";
import UPagination from "@/ui-lib/pagination/Pagination.vue";
import {CompilationSelectModalParams} from "@/components/modals/CompilationSelectModal.vue";

export type TracksSelectModalParams = {
  selected?: number[];
  onSelect?: () => void;
  excludePlaylistID?: number;
}

@Component({
  components: {
    UPagination,
    TrackTableList,
  },
})
export default class extends Vue {
  private selected: number[] = [];
  private onSelect: () => void = (() => {
    return;
  });
  private excludePlaylistID: number | null = null;

  private start = 0;
  private limit = 20;
  private isLoading = false;
  private list: TrackListItem[] = [];
  private total = 0;
  private compilation: CompilationListItem | null = null;

  private close(): void {
    this.$modal.hide('tracks-select');
  }

  private beforeOpen({params}: { params: TracksSelectModalParams }) {
    this.start = 0;
    this.isLoading = false;
    this.list = [];
    this.total = 0;
    this.compilation = null;
    this.selected = params?.selected || [];
    this.onSelect = params?.onSelect || (() => {
      return;
    });
    this.excludePlaylistID = params?.excludePlaylistID || null;

    this.load();
  }

  private async load() {
    this.isLoading = true;

    try {
      const params: TrackServiceListParams = {
        start: this.start,
        limit: this.limit,
      };

      if (this.compilation) {
        params.compilationsIds = [this.compilation.id];
      }

      if (this.excludePlaylistID) {
        params.excludePlaylistID = this.excludePlaylistID;
      }

      const {list, total} = await TrackService.list(params);

      this.list = list;
      this.total = total;
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private get tableFields(): TableField[] {
    return [
      {
        name: 'select',
        label: '',
        width: '52px',
      },

      {
        name: 'play-pause',
        label: '',
        width: '54px',
      },

      {
        name: 'id',
        label: 'ID',
        width: '65px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'likes',
        label: 'Лайки',
        width: '85px',
        align: 'right',
      },

      {
        name: 'dislikes',
        label: 'Дизлайки',
        width: '110px',
        align: 'right',
      },

      {
        name: 'listeners_per_month',
        label: 'Слуш/мес',
        width: '115px',
        align: 'right',
      },

      {
        name: 'skips_per_month',
        label: 'Скип/мес',
        width: '115px',
        align: 'right',
      },
    ];
  }

  private changeStart(val: number) {
    this.start = val;
    this.load();
  }

  private selectCompilation() {
    const params: CompilationSelectModalParams = {
      onSelect: (val) => {
        this.start = 0;
        this.compilation = val;
        this.load();
      },
    };

    this.$modal.show('compilation-select', params);
  }

  private cleanCompilation() {
    this.start = 0;
    this.compilation = null;
    this.load();
  }

  private select() {
    this.onSelect();
    this.close();
  }
}
