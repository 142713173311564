

























import {Component, Vue} from 'vue-property-decorator';
import {Playlist} from "@/types";
import PlaylistFormView from "@/components/playlist/PlaylistForm.vue";


export type PlaylistFormModalParams = {
  playlist?: Playlist;
  onSaved?: (pl: Playlist) => void;
}

@Component({
  components: {
    PlaylistFormView,
  },
})
export default class extends Vue {
  private playlist: Playlist | null = null;
  private onSaved: (pl: Playlist) => void = (() => {
    return;
  });

  private close(): void {
    this.$modal.hide('playlist-form');
  }

  private beforeOpen({params}: { params: PlaylistFormModalParams }) {
    this.playlist = params?.playlist || null;
    this.onSaved = params?.onSaved || (() => {
      return;
    });
  }

  private saved(val: Playlist) {
    this.onSaved(val);
    this.close();
  }
}
