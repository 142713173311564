import {Getters, Mutations, Actions, Module} from 'vuex-smart-module';
import {CompilationListItem} from '@/types';
import {CompilationService} from '@/api';

class CompilationState {
    list: CompilationListItem[] = [];
    listLoaded = false;
}

class CompilationMutations extends Mutations<CompilationState> {
    setList(list: CompilationListItem[]) {
        this.state.list = list;
    }

    setListLoaded(listLoaded: boolean) {
        this.state.listLoaded = listLoaded;
    }
}

class CompilationGetters extends Getters<CompilationState> {
}

class CompilationActions extends Actions<CompilationState, CompilationGetters, CompilationMutations, CompilationActions> {
    async loadList() {
        if (this.state.listLoaded) {
            return;
        }

        const list = await CompilationService.list();
        this.mutations.setList(list);
        this.mutations.setListLoaded(true);
    }
}

export const compilation = new Module({
    state: CompilationState,
    getters: CompilationGetters,
    mutations: CompilationMutations,
    actions: CompilationActions,
});
