import {Getters, Mutations, Actions, Module} from 'vuex-smart-module';
import {Genre} from '@/types';
import {GenreService} from '@/api';

class GenreState {
  list: Genre[] = [];
  listLoaded = false;
}

class GenreMutations extends Mutations<GenreState> {
  setList(list: Genre[]) {
    this.state.list = list;
  }

  setListLoaded(listLoaded: boolean) {
    this.state.listLoaded = listLoaded;
  }
}

class GenreGetters extends Getters<GenreState> {
}

class GenreActions extends Actions<GenreState, GenreGetters, GenreMutations, GenreActions> {
  async loadList() {
    if (this.state.listLoaded) {
      return;
    }

    const list = await GenreService.list();
    this.mutations.setList(list);
    this.mutations.setListLoaded(true);
  }
}

export const genre = new Module({
  state: GenreState,
  getters: GenreGetters,
  mutations: GenreMutations,
  actions: GenreActions,
});
