export interface Compilation {
  id: number;
  genreId: number;
  name: string;
  tracksCount: number;
  createdAt: string;
}

export interface CompilationListItem {
  id: number;
  genreId: number;
  name: string;
}

export interface CompilationTrackCount {
  id: number;
  count: number;
}


export const createCompilation = (): Compilation => {
  return {
    id: 0,
    genreId: 0,
    name: '',
    tracksCount: 0,
    createdAt: '',
  };
};
