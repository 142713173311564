var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":"playlists-select","scrollable":true,"height":"auto","adaptive":true,"top":20,"width":900},on:{"before-open":_vm.beforeOpen}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"modal_content"},[_c('div',{staticClass:"modal_title"},[_vm._v(" Выбрать плейлисты ")]),_c('u-table',{attrs:{"data":_vm.list,"fields":_vm.tableFields},scopedSlots:_vm._u([{key:"column_select",fn:function(ref){
var row = ref.row;
return [_c('u-checkbox',{attrs:{"value":_vm.selected.includes(row.id)},on:{"input":function($event){return _vm.toggleSelect(row, $event)}}})]}},{key:"column_image",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlists-select-modal_image"},[_c('img',{attrs:{"src":row.image ? row.image : '/images/no_image_40px.png'}})])]}},{key:"column_musicPresetIDs",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlists-select-modal_preset"},[_vm._v(" "+_vm._s(_vm.presetsName(row))+" ")])]}},{key:"column_totalTracks",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlists-select-modal_totalTracks"},[_vm._v(" "+_vm._s(row.totalTracks.toLocaleString())+" ")])]}},{key:"column_totalDuration",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlists-select-modal_totalDuration"},[_vm._v(" "+_vm._s(_vm.durationText(row))+" ")])]}}])}),_c('div',{staticClass:"playlists-select-modal_select"},[_c('div',{staticClass:"playlists-select-modal_select_count"},[_vm._v(" Выбрано: "+_vm._s(_vm.selected.length)+" ")]),_c('u-button',{attrs:{"small":"","theme":"gray-outline"},on:{"click":_vm.selectAll}},[_vm._v(" Выбрать все ")]),(_vm.selectedList.length)?_c('u-button',{attrs:{"small":"","theme":"gray-outline"},on:{"click":_vm.unselectAll}},[_vm._v(" Снять выбор ")]):_vm._e(),_c('div',{staticClass:"playlists-select-modal_select_next"},[_c('u-button',{attrs:{"small":""},on:{"click":_vm.select}},[_vm._v(" Продолжить ")])],1)],1),(_vm.total)?_c('u-pagination',{attrs:{"total":_vm.total,"start":_vm.start,"limit":_vm.limit},on:{"change-start":_vm.changeStart}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }