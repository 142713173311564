




















import {Component, Vue} from 'vue-property-decorator';
import TrackSelectCompilations from '@/components/track/select-compilations/TrackSelectCompilations.vue';
import UButton from '@/ui-lib/button/Button.vue';
import {CompilationListItem, TrackListItem} from "@/types";
import {TrackDistributionService} from "@/api";
import {compilation} from "@/store/modules/compilation";

export type Params = {
  track: TrackListItem;
}

@Component({
  components: {
    TrackSelectCompilations,
    UButton,
  }
})
export default class extends Vue {
  private selected: number[] = [];
  private track: TrackListItem | null = null;
  private loading = false;

  private close(): void {
    this.$modal.hide('track-change-compilation');
  }

  private beforeOpen({params}: { params: Params }) {
    this.selected = params.track.compilations.map((tc) => tc.id);
    this.track = params.track;
  }

  private async save() {
    if (!this.track) {
      return
    }

    this.loading = true;

    try {
      await TrackDistributionService.save(this.track.id, this.selected);
      this.track.compilations = this.compilations.filter((c) => this.selected.includes(c.id));
      this.close();
    } catch (e) {
      alert(e?.message || 'Ошибка');
    }

    this.loading = false;
  }

  private get compilations(): CompilationListItem[] {
    return compilation.context(this.$store).state.list;
  }
}
