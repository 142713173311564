export const convertTimeHHMMSS = (val: string | number): string => {
  val = parseInt(val.toString(), 10);

  const hhmmss: string = new Date(val * 1000).toISOString().substr(11, 8);

  // если есть часы
  if (hhmmss.indexOf('00:') !== 0) {
    return hhmmss;
  } else {
    return hhmmss.substr(3, 1) === '0' ? hhmmss.substr(4) : hhmmss.substr(3);
  }
};
