import {Combination, CombinationCompilation} from '@/types';
import {api} from '@/api/api';

export interface CombinationCompilationForSave {
    compilationId: number;
    probability: number;
}

export const CombinationService = {
    item(combinationId: number): Promise<Combination> {
        return api.get(`/combination/${combinationId}`);
    },

    compilations(combinationId: number): Promise<CombinationCompilation[]> {
        return api.get(`/combination/${combinationId}/compilations`);
    },

    compilationsSave(combinationId: number, items: CombinationCompilationForSave[]) {
        return api.put(`/combination/${combinationId}/compilations`, {
            items,
        });
    }
};
