import {api} from './api';
import {Track} from '@/types';

export interface TrackDistributionLoad {
    left: number;
    track: Track | null;
}

export const TrackDistributionService = {
    load(): Promise<TrackDistributionLoad> {
        return api.get('/track-distribution/load');
    },

    skip(trackId: number): Promise<void> {
        return api.post(`/track-distribution/skip/${trackId}`);
    },

    save(trackId: number, selected: number[]): Promise<void> {
        return api.post(`/track-distribution/save/${trackId}`, {
            selected,
        });
    },

    notSuitable(trackId: number): Promise<void> {
        return api.post(`/track-distribution/not-suitable/${trackId}`);
    }
};
