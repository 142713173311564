


















































































































































































import {Component, Vue} from 'vue-property-decorator';

export interface MusicPlayerTrack {
    id: number;
    trackTitle: string;
    artistName: string;
    src: string;
    image?: string;
}

interface State {
    isPlaying: boolean;
    playingTrack: MusicPlayerTrack | null;
}

const _bus = new Vue();

const state: State = Vue.observable({
    isPlaying: false,
    playingTrack: null,
});

class Player {
    public get playingTrack() {
        return state.playingTrack;
    }

    public get isPlaying() {
        return state.isPlaying;
    }

    public togglePlay(track: MusicPlayerTrack) {
        _bus.$emit('togglePlay', track);
    }

    public close() {
        _bus.$emit('close');
    }
}

export const player = new Player();

@Component
export default class MusicPlayer extends Vue {
    private progress = 0;
    private playingDuration = 0;
    private audio = new Audio();

    get progressPassed(): number {
        return this.progress / this.duration * 100;
    }

    get duration(): number {
        return this.playingDuration;
    }

    get track() {
        return state.playingTrack;
    }

    set track(track: MusicPlayerTrack | null) {
        state.playingTrack = track;
    }

    get isPlaying() {
        return state.isPlaying;
    }

    set isPlaying(val: boolean) {
        state.isPlaying = val;
    }

    get title(): string {
        return this.track?.trackTitle || '';
    }

    get image(): string | null {
        return this.track?.image || null;
    }

    get imageStyles() {
        return {
            backgroundImage: this.image ? `url('${this.image}')` : null,
        };
    }

    get artistsText(): string {
        return this.track?.artistName || '';
    }

    private play() {
        this.isPlaying = true;
        this.audio.play();
    }

    private pause() {
        this.isPlaying = false;
        this.audio.pause();
    }

    private stop() {
        this.isPlaying = false;
        this.audio.src = '';
    }

    private ended() {
        this.stop();
    }

    private close() {
        this.stop();
        this.track = null;
    }

    private _addPlayEvents() {
        this.audio.addEventListener('loadedmetadata', this._handleAudioLoadedMetaData);
    }

    private _removePlayEvents() {
        this.audio.removeEventListener('loadedmetadata', this._handleAudioLoadedMetaData);
        this.audio.addEventListener('timeupdate', this._handleAudioTimeUpdate);
    }

    private _handleAudioLoadedMetaData() {
        this.playingDuration = this.audio.duration;

        this.audio.addEventListener('timeupdate', this._handleAudioTimeUpdate);
    }

    private _handleAudioTimeUpdate() {
        this.progress = this.audio.currentTime;

        if (this.progress >= this.playingDuration) {
            this.ended();
        }
    }

    private progressClickHandler(e: MouseEvent) {
        if (!(e.target instanceof HTMLElement)) {
            return;
        }

        this.audio.currentTime = e.offsetX / e.target.offsetWidth * this.duration;
    }

    private playHandler(track: MusicPlayerTrack) {
        this.track = track;
        this.audio.src = track.src;
        this.play();
    }

    private togglePlay() {
        if (this.isPlaying) {
            this.pause();
        } else {
            this.play();
        }
    }

    private togglePlayHandler(track: MusicPlayerTrack) {
        if (this.track && this.track.id === track.id) {
            this.togglePlay();
            return;
        }

        this.playHandler(track);
    }

    private closeHandler() {
        this.close();
    }

    private mounted() {
        _bus.$on('togglePlay', this.togglePlayHandler);
        _bus.$on('close', this.closeHandler);
        this._addPlayEvents();
    }

    private beforeDestroy() {
        _bus.$off('togglePlay', this.togglePlayHandler);
        _bus.$off('close', this.closeHandler);
        this._removePlayEvents();
    }
}
