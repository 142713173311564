




import {Component, Vue} from 'vue-property-decorator';
import '@/assets/icons/vue/close';

@Component
export default class Deselect extends Vue {
}
