export interface Playlist {
  id: number;
  createdAt: string;
  updatedAt: string;
  pos: number;
  musicPresetIDs: number[];
  title: string;
  description?: string | null;
  image?: string | null;
  totalTracks: number;
  totalDuration: number;
  totalUses: number;
  dynamics: number | null;
  acoustics: number | null;
}

export const createPlaylist = (): Playlist => ({
  id: 0,
  createdAt: '',
  updatedAt: '',
  pos: 0,
  musicPresetIDs: [],
  title: '',
  totalTracks: 0,
  totalDuration: 0,
  totalUses: 0,
  dynamics: null,
  acoustics: null,
})

export interface PlaylistForm {
  id?: number;
  musicPresetIDs: number[];
  title: string;
  description: string;
  image: File | null;
  dynamics: number | null;
  acoustics: number | null;
}

export const createPlaylistForm = (pl?: Playlist): PlaylistForm => ({
  id: pl ? pl.id : undefined,
  musicPresetIDs: pl?.musicPresetIDs ? [...pl?.musicPresetIDs] : [],
  title: pl?.title || '',
  description: pl?.description || '',
  image: null,
  dynamics: pl?.dynamics || null,
  acoustics: pl?.acoustics || null,
});
