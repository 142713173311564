



















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Genre, CompilationListItem} from '@/types';
import {compilation} from '@/store/modules/compilation';
import TrackSelectCompilationsItem from './TrackSelectCompilationsItem.vue';

@Component({
    components: {
        TrackSelectCompilationsItem,
    },
})
export default class TrackSelectCompilationsGenre extends Vue {
    @Prop({required: true})
    private genre!: Genre;

    @Prop({type: Array, default: () => []})
    private selected!: number[];

    private isOpen = false;

    private get compilations(): CompilationListItem[] {
        return compilation.context(this.$store).state.list.filter((c) => c.genreId === this.genre.id);
    }

    private get name(): string {
        return this.genre.name;
    }

    private get info(): string {
        return this.compilations.map((c) => {
            return this.selected.includes(c.id) ? `<span>${c.name}</span>` : c.name;
        }).join(', ')
    }

    private toggleOpen() {
        this.isOpen = !this.isOpen;
    }
}
