import Vue from 'vue';
import Modal from './Modal.vue';
import Dialog from './Dialog.vue';
import ModalsContainer from './ModalsContainer.vue';

const defaultComponentName = 'modal';

const plugin: any = {
    opened: [],

    installed: false,

    install(vue: Vue, options = {} as any) {
        /**
         * Makes sure that plugin can be insstalled only once
         */
        if (this.installed) {
            return;
        }

        this.installed = true;
        this.event = new Vue();
        this.dynamicContainer = null;
        this.componentName = options.componentName || defaultComponentName;

        /**
         * Plugin API
         */
        Vue.prototype.$modal = {
            _setDynamicContainer(dynamicContainer: any) {
                plugin.dynamicContainer = dynamicContainer;
            },

            show(modal: string | typeof Vue, paramsOrProps: object, params: object, events = {} as any) {
                if (typeof modal === 'string') {
                    plugin.event.$emit('toggle', modal, true, paramsOrProps);
                } else {
                    if (plugin.dynamicContainer === null) {
                        console.warn(`[vue-js-modal] In order to render dynamic modals,
                                      a <modals-container> component must be present on the page`);
                    } else {
                        plugin.dynamicContainer.add(modal, paramsOrProps, params, events);
                    }
                }
            },

            hide(name: string, params: object) {
                plugin.event.$emit('toggle', name, false, params);
            },

            toggle(name: string, params: object) {
                plugin.event.$emit('toggle', name, undefined, params);
            },
        };

        /**
         * Sets custom component name (if provided)
         */
        Vue.component(this.componentName, Modal);

        /**
         * Registration of <Dialog/> component
         */
        if (options.dialog) {
            Vue.component('v-dialog', Dialog);
        }

        /**
         * Registration of <ModalsContainer/> component
         */
        if (options.dynamic) {
            Vue.component('modals-container', ModalsContainer);
        }
    },
};

export default plugin;
