/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'playlist-music-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 6v2H3V6h12zm0 4v2H3v-2h12zM3 16v-2h8v2H3zM17 6h5v2h-3v9c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3c.35 0 .69.07 1 .18V6zm-1 10c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" _fill="#000"/>'
  }
})
