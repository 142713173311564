/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.113 7.057L12 13.17 5.887 7.057 4 8.943l8 8 8-8-1.887-1.886z" _fill="#000"/>'
  }
})
