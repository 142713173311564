import {config} from '@/config';

export type ValidationFails = Record<string, string[]>;

export interface APIErrorData {
  message: string;
  statusCode: number;
  validationFails?: ValidationFails;
}

export interface APIError extends Error {
  statusCode?: number;
  validationFails?: ValidationFails;
}

class APIErrorConstructor extends Error implements APIError {
  public statusCode!: number;
  public validationFails!: ValidationFails;

  constructor(data: APIErrorData) {
    super(data.message);

    this.statusCode = data.statusCode;

    if (data.validationFails) {
      this.validationFails = data.validationFails;
    }
  }
}

// eslint-disable-next-line
const encodeQueryData = (data: Record<string, any>) => {
  const ret = [];

  for (const prop in data) {
    ret.push(encodeURIComponent(prop) + '=' + encodeURIComponent(data[prop]));
  }

  return ret.join('&');
};

export const api = {
  // eslint-disable-next-line
  async request(uri: string, method: string, body?: any) {
    const headers: Record<string, string> = {
      'Accept': 'application/json',
    };

    const init: RequestInit = {
      method: method,
      headers,
      credentials: 'include',
    };

    if (body !== undefined) {
      if (body instanceof FormData) {
        init.body = body;
      } else {
        init.body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }
    }

    const response = await fetch(config.API_URL + uri, init);
    const json = await response.json();

    if (response.status !== 200) {
      if ('error' in json) {
        throw new APIErrorConstructor(json.error);
      }

      throw new Error(`HTTP Error: ${response.status}`);
    }

    if ('data' in json) {
      return json.data;
    } else {
      throw new Error('No Data');
    }
  },

  // eslint-disable-next-line
  get(uri: string, query?: Record<string, any>) {
    if (query) {
      uri += '?' + encodeQueryData(query);
    }

    return api.request(uri, 'GET');
  },

  // eslint-disable-next-line
  post(uri: string, body?: any) {
    return api.request(uri, 'POST', body);
  },

  // eslint-disable-next-line
  put(uri: string, body?: any) {
    return api.request(uri, 'PUT', body);
  },
};
