export interface Combination {
  id: number;
  presetId: number;
  dynamics: number;
  acoustics: number;
  compilationCount: number;
  trackCount: number;
}

export interface CombinationCompilation {
  id: number;
  compilationId: number;
  probability: number;
  trackCount: number;
}

export const createCombination = (): Combination => {
  return {
    id: 0,
    presetId: 0,
    dynamics: 0,
    acoustics: 0,
    compilationCount: 0,
    trackCount: 0,
  };
}
