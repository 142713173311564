<template>
  <div v-if="visibility.overlay"
       ref="overlay"
       :style="{zIndex}"
       :class="overlayClass"
       :aria-expanded="visible.toString()"
       :data-modal="name">
    <div :class="backgroundClickClass"
         @mousedown.self="onBackgroundClick"
         @touchstart.self="onBackgroundClick">
      <transition :name="transition">
        <div v-if="visibility.modal"
             ref="modal"
             :class="modalClass"
             :style="modalStyle">
          <div class="v--modal-close"
               :class="{ 'v--modal-close_top': isCloseTop }"
               @click="onCloseClick">
            <icon name="close"/>
          </div>

          <slot/>

          <resizer v-if="resizable && !isAutoHeight"
                   :min-width="minWidth"
                   :min-height="minHeight"
                   @resize="onModalResize"/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Modal from './index';
import Resizer from './Resizer.vue';
import {inRange} from './util';
import parseNumber from './parser';

export default {
  name: 'VueJsModal',
  props: {
    name: {
      required: true,
      type: String,
    },

    delay: {
      type: Number,
      default: 0,
    },

    top: {
      type: Number,
      default: undefined,
    },

    resizable: {
      type: Boolean,
      default: false,
    },

    adaptive: {
      type: Boolean,
      default: false,
    },

    draggable: {
      type: [Boolean, String],
      default: false,
    },

    scrollable: {
      type: Boolean,
      default: false,
    },

    reset: {
      type: Boolean,
      default: false,
    },

    transition: {
      type: String,
      default: 'vt-fade-fast',
    },

    clickToClose: {
      type: Boolean,
      default: true,
    },

    classes: {
      type: [String, Array],
      default: 'v--modal',
    },

    minWidth: {
      type: Number,
      default: 0,

      validator(value) {
        return value >= 0;
      },
    },

    minHeight: {
      type: Number,
      default: 0,

      validator(value) {
        return value >= 0;
      },
    },

    maxWidth: {
      type: Number,
      default: Infinity,
    },

    maxHeight: {
      type: Number,
      default: Infinity,
    },

    width: {
      type: [Number, String],
      default: 600,

      validator(value) {
        if (typeof value === 'string') {
          const width = parseNumber(value);
          return (width.type === '%' || width.type === 'px') && width.value > 0;
        }

        return value >= 0;
      },
    },

    height: {
      type: [Number, String],
      default: 300,

      validator(value) {
        if (typeof value === 'string') {
          if (value === 'auto') {
            return true;
          }

          const height = parseNumber(value);

          return (
                  (height.type === '%' || height.type === 'px') && height.value > 0
          );
        }

        return value >= 0;
      },
    },

    zIndex: {
      type: Number,
      default: 2000,
    },

    pivotX: {
      type: Number,
      default: 0.5,

      validator(value) {
        return value >= 0 && value <= 1;
      },
    },

    pivotY: {
      type: Number,
      default: 0.5,

      validator(value) {
        return value >= 0 && value <= 1;
      },
    },

    hasCheckRoute: {
      type: Boolean,
      default: true,
    },

    queryName: {
      type: String,
    },
  },

  components: {
    Resizer,
  },

  data() {
    return {
      visible: false,
      queryValue: '',

      visibility: {
        modal: false,
        overlay: false,
      },

      shift: {
        left: 0,
        top: 0,
      },

      modal: {
        width: 0,
        widthType: 'px',
        height: 0,
        heightType: 'px',
        renderedHeight: 0,
      },

      window: {
        width: 0,
        height: 0,
      },

      mutationObserver: null,

      modalsOpened: Modal.opened,
    };
  },

  watch: {
    /**
     * Sets the visibility of overlay and modal.
     * Events 'opened' and 'closed' is called here
     * inside `setTimeout` and `$nextTick`, after the DOM changes.
     * This fixes `$refs.modal` `undefined` bug (fixes #15)
     */
    visible(value) {
      if (value) {
        this.visibility.overlay = true;

        setTimeout(() => {
          this.visibility.modal = true;

          this.$nextTick(() => {
            this.addDraggableListeners();
            this.callAfterEvent(true);
          });
        }, this.delay);
      } else {
        this.visibility.modal = false;

        setTimeout(() => {
          this.visibility.overlay = false;

          this.$nextTick(() => {
            this.removeDraggableListeners();
            this.callAfterEvent(false);
          });
        }, this.delay);
      }
    },

    $route(val) {
      this.checkRoute(val);
    },
  },

  created() {
    this.setInitialSize();
  },

  /**
   * Sets global listeners
   */
  beforeMount() {
    this.checkRoute();

    Modal.event.$on('toggle', (name, state, params) => {
      if (name === this.name) {
        if (typeof state === 'undefined') {
          state = !this.visible;
        }

        this.toggle(state, params);
      }

      if (name === undefined) {
        this.toggle(false);
      }
    });

    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();

    /**
     * Making sure that autoHeight is enabled when using "scrollable"
     */
    if (this.scrollable && !this.isAutoHeight) {
      console.warn(
              `Modal "${this.name}" has scrollable flag set to true ` +
              `but height is not "auto" (${this.height})`,
      );
    }

    /**
     * Only observe when using height: 'auto'
     * The callback will be called when modal DOM changes,
     * this is for updating the `top` attribute for height 'auto' modals.
     */
    if (this.isAutoHeight) {
      /**
       * MutationObserver feature detection:
       * Detects if MutationObserver is available, return false if not.
       * No polyfill is provided here, so height 'auto' recalculation will
       * simply stay at its initial height (won't crash).
       * (Provide polyfill to support IE < 11)
       */
      const MutationObserver = (() => {
        const prefixes = ['', 'WebKit', 'Moz', 'O', 'Ms'];

        for (const prefix of prefixes) {
          const name = prefix + 'MutationObserver';

          if (name in window) {
            return window[name];
          }
        }

        return false;
      })();

      if (MutationObserver) {
        this.mutationObserver = new MutationObserver(() => {
          this.updateRenderedHeight();
        });
      }
    }

    if (this.clickToClose) {
      window.addEventListener('keyup', this.onEscapeKeyUp);
    }
  },
  /**
   * Removes "resize" window listener
   */
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);

    if (this.clickToClose) {
      window.removeEventListener('keyup', this.onEscapeKeyUp);
    }

    const openedIndex = Modal.opened.findIndex((n) => n === this.name);

    if (openedIndex > -1) {
      Modal.opened.splice(openedIndex, 1);
    }
  },

  computed: {
    hidden() {
      if (this.modalsOpened.length) {
        if (this.modalsOpened[this.modalsOpened.length - 1] !== this.name) {
          return true;
        }
      }

      return false;
    },

    isCloseTop() {
      return this.window.width < (this.width + 100);
    },

    /**
     * Returns true if height is set to "auto"
     */
    isAutoHeight() {
      return this.modal.heightType === 'auto';
    },

    /**
     * Calculates and returns modal position based on the
     * pivots, window size and modal size
     */
    position() {
      const {
        window,
        shift,
        pivotX,
        pivotY,
        trueModalWidth,
        trueModalHeight,
        top: modalTop,
      } = this;

      const maxLeft = window.width - trueModalWidth;
      const maxTop = window.height - trueModalHeight;

      const left = shift.left + pivotX * maxLeft;
      const top = shift.top + pivotY * maxTop;

      return {
        left: inRange(0, maxLeft, left),
        top: modalTop !== undefined ? modalTop : inRange(0, maxTop, top),
      };
    },

    /**
     * Returns pixel width (if set with %) and makes sure that modal size
     * fits the window
     */
    trueModalWidth() {
      const {window, modal, adaptive, minWidth, maxWidth} = this;

      const value = modal.widthType === '%' ? window.width / 100 * modal.width : modal.width;

      const max = Math.min(window.width, maxWidth);

      return adaptive ? inRange(minWidth, max, value) : value;
    },

    /**
     * Returns pixel height (if set with %) and makes sure that modal size
     * fits the window.
     *
     * Returns modal.renderedHeight if height set as "auto"
     */
    trueModalHeight() {
      const {window, modal, isAutoHeight, adaptive, maxHeight} = this;

      const value = modal.heightType === '%' ? window.height / 100 * modal.height : modal.height;

      if (isAutoHeight) {
        return this.modal.renderedHeight;
      }

      const max = Math.min(window.height, maxHeight);

      return adaptive ? inRange(this.minHeight, max, value) : value;
    },

    /**
     * Returns class list for screen overlay (modal background)
     */
    overlayClass() {
      return {
        'v--modal-overlay': true,
        'scrollable': this.scrollable && this.isAutoHeight,
        'v--modal-overlay__hidden': this.hidden,
      };
    },

    /**
     * Returns class list for click outside overlay (background click)
     */
    backgroundClickClass() {
      return ['v--modal-background-click'];
    },

    /**
     * Returns class list for modal itself
     */
    modalClass() {
      return ['v--modal-box', this.classes];
    },

    /**
     * CSS styles for position and size of the modal
     */
    modalStyle() {
      return {
        top: this.position.top + 'px',
        left: this.position.left + 'px',
        width: this.trueModalWidth + 'px',
        height: this.isAutoHeight ? 'auto' : this.trueModalHeight + 'px',
      };
    },

    closeBtnWrapStyle() {
      return {
        width: this.trueModalWidth + 'px',
        left: this.position.left + 'px',
      };
    },
  },

  methods: {
    checkRoute() {
      if (this.queryName === undefined) {
        return;
      }

      const q = this.$route.query;

      if (this.queryName in q) {
        this.toggle(true, {
          queryValue: q[this.queryName],
        });
      } else {
        this.toggle(false);
      }
    },

    changeRoute(state) {
      if (this.queryName === undefined) {
        return;
      }

      const r = this.$route;
      const q = Object.assign({}, r.query);

      if (state && !(this.queryName in q)) {
        this.$router.push({
          name: r.name,
          query: {
            ...q,
            [this.queryName]: this.queryValue,
          },
          params: r.params,
        });
      }

      if (!state && (this.queryName in q)) {
        delete q[this.queryName];

        this.$router.push({
          name: r.name,
          query: q,
          params: r.params,
        });
      }
    },


    /**
     * Initializes modal's size & position,
     * if "reset" flag is set to true - this function will be called
     * every time "beforeOpen" is triggered
     */
    setInitialSize() {
      const {modal} = this;
      const width = parseNumber(this.width);
      const height = parseNumber(this.height);

      modal.width = width.value;
      modal.widthType = width.type;
      modal.height = height.value;
      modal.heightType = height.type;
    },

    onEscapeKeyUp(event) {
      if (event.which === 27 && this.visible && !this.hidden) {
        this.$modal.hide(this.name);
      }
    },

    onWindowResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    /**
     * Generates event object
     */
    genEventObject(params) {
      const eventData = {
        name: this.name,
        timestamp: Date.now(),
        canceled: false,
        ref: this.$refs.modal,
      };

      return Object.assign(eventData, params || {});
    },

    /**
     * Event handler which is triggered on modal resize
     */
    onModalResize(event) {
      this.modal.widthType = 'px';
      this.modal.width = event.size.width;

      this.modal.heightType = 'px';
      this.modal.height = event.size.height;

      const {size} = this.modal;
      const resizeEvent = this.genEventObject({size});

      this.$emit('resize', resizeEvent);
    },

    /**
     * Event handler which is triggered on $modal.show and $modal.hide
     * BeforeEvents: ('before-close' and 'before-open') are `$emit`ed here,
     * but AfterEvents ('opened' and 'closed') are moved to `watch.visible`.
     */
    toggle(state, params) {
      const {reset, scrollable, visible} = this;
      if (visible === state) {
        return;
      }
      const beforeEventName = visible ? 'before-close' : 'before-open';

      if (beforeEventName === 'before-open') {
        /**
         * Need to unfocus previously focused element, otherwise
         * all keypress events (ESC press, for example) will trigger on that element.
         */
        if (document.activeElement) {
          document.activeElement.blur();
        }

        if (reset) {
          this.setInitialSize();

          this.shift.left = 0;
          this.shift.top = 0;
        }

        if (scrollable) {
          document.getElementsByTagName('html')[0].classList.add('v--modal-block-scroll');
          document.body.classList.add('v--modal-block-scroll');
        }
      } else {
        if (scrollable) {
          setTimeout(() => {
            document.getElementsByTagName('html')[0].classList.remove('v--modal-block-scroll');
            document.body.classList.remove('v--modal-block-scroll');
          }, this.delay);
        }
      }

      let stopEventExecution = false;

      const stop = () => {
        stopEventExecution = true;
      };

      const beforeEvent = this.genEventObject({stop, state, params});

      this.$emit(beforeEventName, beforeEvent);

      if (!stopEventExecution) {
        this.visible = state;

        this.queryValue = params && params.queryValue || '';
        this.changeRoute(state);

        const openedIndex = Modal.opened.findIndex((n) => n === this.name);

        if (openedIndex === -1) {
          Modal.opened.push(this.name);
        } else {
          Modal.opened.splice(openedIndex, 1);
        }
      }
    },

    getDraggableElement() {
      const selector = typeof this.draggable !== 'string' ? '.v--modal-box' : this.draggable;

      if (selector) {
        const handler = this.$refs.overlay.querySelector(selector);

        if (handler) {
          return handler;
        }
      }
    },

    /**
     * Event handler that is triggered when background overlay is clicked
     */
    onBackgroundClick() {
      // if (this.clickToClose) {
      //     this.toggle(false);
      // }
    },

    onCloseClick() {
      this.toggle(false);
    },

    addDraggableListeners() {
      if (!this.draggable) {
        return;
      }

      const dragger = this.getDraggableElement();

      if (dragger) {
        let startX = 0;
        let startY = 0;
        let cachedShiftX = 0;
        let cachedShiftY = 0;

        const getPosition = (event) => {
          return event.touches && event.touches.length > 0 ? event.touches[0] : event;
        };

        const mousemove = (event) => {
          const {clientX, clientY} = getPosition(event);

          this.shift.left = cachedShiftX + clientX - startX;
          this.shift.top = cachedShiftY + clientY - startY;

          event.preventDefault();
        };

        const mouseup = (event) => {
          document.removeEventListener('mousemove', mousemove);
          document.removeEventListener('mouseup', mouseup);

          document.removeEventListener('touchmove', mousemove);
          document.removeEventListener('touchend', mouseup);

          event.preventDefault();
        };

        const mousedown = (event) => {
          const target = event.target;

          if (target && target.nodeName === 'INPUT') {
            return;
          }

          const {clientX, clientY} = getPosition(event);

          document.addEventListener('mousemove', mousemove);
          document.addEventListener('mouseup', mouseup);

          document.addEventListener('touchmove', mousemove);
          document.addEventListener('touchend', mouseup);

          startX = clientX;
          startY = clientY;
          cachedShiftX = this.shift.left;
          cachedShiftY = this.shift.top;
        };

        dragger.addEventListener('mousedown', mousedown);
        dragger.addEventListener('touchstart', mousedown);
      }
    },

    removeDraggableListeners() {
      /*  console.log('removing draggable handlers') */
    },

    /**
     * 'opened' and 'closed' events are `$emit`ed here.
     * This is called in watch.visible.
     * Because modal DOM updates are async,
     * wrapping afterEvents in `$nextTick` fixes `$refs.modal` undefined bug.
     * (fixes #15)
     */
    callAfterEvent(state) {
      if (state) {
        this.connectObserver();
      } else {
        this.disconnectObserver();
      }

      const eventName = state ? 'opened' : 'closed';
      const event = this.genEventObject({state});

      this.$emit(eventName, event);
    },

    /**
     * Update $data.modal.renderedHeight using getBoundingClientRect.
     * This method is called when:
     * 1. modal opened
     * 2. MutationObserver's observe callback
     */
    updateRenderedHeight() {
      if (this.$refs.modal) {
        this.modal.renderedHeight = this.$refs.modal.getBoundingClientRect().height;
      }
    },

    /**
     * Start observing modal's DOM, if childList or subtree changes,
     * the callback (registered in beforeMount) will be called.
     */
    connectObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.observe(this.$refs.modal, {
          childList: true,
          attributes: true,
          subtree: true,
        });
      }
    },

    /**
     * Disconnects MutationObserver
     */
    disconnectObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
  },
};
</script>
<style type="text/scss" lang="scss">
.v--modal-block-scroll {
  overflow: hidden;
  width: 100%;
}

.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  opacity: 1;

  &__hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &.scrollable {
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .v--modal-background-click {
    min-height: 100%;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .v--modal-box {
    position: relative;
    box-sizing: border-box;
  }

  &.scrollable .v--modal-box {
    //margin-bottom: 2px;
  }
}

.v--modal {
  background-color: white;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  padding: 0;

  &.v--modal-fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
    left: 0;
    top: 0;
  }

  &-close {
    color: white;
    position: absolute;
    top: -22px;
    right: -22px;
    display: flex;
    cursor: pointer;

    &_top {
      top: -23px;
      right: 0;
    }

    opacity: .7;

    &:hover {
      opacity: 1;
    }

    svg {
      display: block;
      margin-left: auto;
      width: 24px;
      height: 24px;

    }
  }
}

.modal {
  &_content {
    padding: 25px 30px;
    border-radius: 10px;
  }

  &_title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: .8em;
  }

  &_desc {
    color: #72767B;
    font-size: 15px;
  }

  &_btns {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -15px;

    > *,
    &_right > * {
      margin-left: 15px;
      margin-top: 10px;
    }

    &_right {
      margin-left: auto;
      margin-top: 0;
    }
  }
}
</style>
