import {api} from './api';
import {MrUser} from '@/types';

export interface AuthLoginBody {
    email: string;
    password: string;
}

export const AuthService = {
    login(body: AuthLoginBody): Promise<MrUser> {
        return api.post('/auth/login', body);
    },

    logout(): Promise<void> {
        return api.post('/auth/logout');
    },

    current(): Promise<MrUser> {
        return api.get('/auth/current');
    },
};
